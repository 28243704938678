import React from "react";

const ErrorPage = () => {

    return (
        <div>
            <p> Aqui tem um erro em!</p>
        </div>
    );

}

export default ErrorPage