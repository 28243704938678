import React from "react";
import "./styles.css";
import instagramIconBW from '../../assets/images/png/instagram_bw.png'
import facebookIconBW from '../../assets/images/png/facebookbw.png'
import { Tooltip } from 'react-tooltip'
import airbnbbw from '../../assets/images/png/airbnbbw.png'
/* import { Helmet } from 'react-helmet' */


// Atenção: Não esquecer de alterar também no arquivo headerHome, que é utilizado
// na página Home

export default function Header() {

    return (
        <div>
{/*             <Helmet>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"></link>
                <meta charset="UTF-8" />
            </Helmet> */}

            <header className="header fixed-top">
                <div className="socialMedia1 ">
                    <div className="socialBar container d-flex align-items-end justify-content-end pt-1">
                        <a href='https://www.instagram.com/repsemdestino/'
                            data-tooltip-id="instagram-tooltip"
                            data-tooltip-content="Acesse nosso Instagram!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={instagramIconBW}
                                width={25}
                                alt="Logotipo instagram"
                                className="socialMediaSpace"
                            />
                            <Tooltip id="instagram-tooltip" />
                        </a>
                        <a href='https://www.facebook.com/RepublicaSemDestino/'
                            data-tooltip-id="facebook-tooltip"
                            data-tooltip-content="Acesse nossa página no Facebook!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={facebookIconBW}
                                width={25}
                                alt="Logotipo facebook"
                                className="socialMediaSpace" />
                            <Tooltip id="facebook-tooltip" />
                        </a>
                        <a href='https://www.airbnb.com.br/users/show/32669058'
                            data-tooltip-id="airbnb-tooltip"
                            data-tooltip-content="Acesse nossa página no AirBnB!"
                            data-tooltip-place="bottom-end"
                            data-tooltip-offset={10}>
                            <img
                                src={airbnbbw}
                                width={25}
                                alt="Logotipo AirBnB"
                                className="socialMediaSpace" />
                            <Tooltip id="airbnb-tooltip" />
                        </a>
                    </div>
                </div>
            </header >
        </div>
    );
}
