import React from 'react';
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/header';
import PlotlySection1 from './plotlySection1';
import PlotlySection2 from './plotlySection2';

const PageChart = () => {

    return (
        <div>
            <Header></Header>
            <div className='initialSpace'></div>
            <div className='container-fluid w-80 chartHeight justify-content-center align-items-center'>
                <PlotlySection1></PlotlySection1>
                <PlotlySection2></PlotlySection2>
            </div>

        </div>
    );

}

export default PageChart