import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PageUnderConstruction from './pages/underConstruction/index';
import PageChart from './pages/plotlySection/index';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/error/errorPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([

    {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <PageUnderConstruction />
            },
            {
                path: "/fundChart",
                element: <PageChart />
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
