import React from 'react';
import logo from '../../assets/svg/logo.svg'
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import instragramColorIcon from '../../assets/images/png/instagram_color.png'
import facebookColorIcon from '../../assets/images/png/facebook_color.png'
import airbnbColorIcon from '../../assets/images/png/airbnb_color.png'
import spotifyColorIcon from '../../assets/images/png/spotify_color.png'


const PageUnderConstruction = () => {
    return (
        <div className="container-fluid vh-100 w-100  justify-content-center align-items-center">
            <div className="row vh-100">
                {/* Primeira coluna com imagem */}
                <div className="col-md-6 d-flex justify-content-center align-items-center bg" >
                    <img
                        src={logo}
                        alt="Em breve"
                        width={270}
                    />
                </div>

                {/* Segunda coluna com imagem e campo de texto */}
                <div className="col-md-6 d-flex justify-content-center align-items-center bg-w">
                    <div className='row largura-60'>
                        <p className='justify-content-center align-items-center fc-w'>
                            Siga a República Sem Destino nas redes sociais!
                        </p>
                        {/*                         <p className='justify-content-center align-items-center fc-w'>
                            Aguarde para mais.
                        </p> */}

                        <div className='row'>
                            <div className='col-4'>
                                <a href='https://www.instagram.com/repsemdestino/'>
                                    <img
                                        src={instragramColorIcon}
                                        alt="Instagram Icon"
                                        className='App-logo-instagram'

                                    />
                                </a>
                            </div>
                            <div className='col-4'>
                                <a href='https://www.facebook.com/RepublicaSemDestino/'>
                                    <img
                                        src={facebookColorIcon}
                                        alt="Facebook Icon"
                                        className='App-logo-instagram'
                                    />
                                </a>
                            </div>
                            <div className='col-4' >
                                <a href='https://www.airbnb.com.br/users/show/32669058'>
                                    <img
                                        src={airbnbColorIcon}
                                        alt="Facebook Icon"
                                        className='App-logo-spotify'

                                    >
                                    </img>
                                </a>
                            </div>
                        </div>

                        <div className='altura-30'></div>
                        <p className='justify-content-center align-items-center fc-w'>
                            Caso queira ouvir um funk das antigas, só acompanhar.<br></br>
                        </p>
                        <div className='col-4' >
                            <a href='https://open.spotify.com/playlist/1ZJNwFKE2u54YFDwaeekGW?si=ae1b244b20ef4203'>
                                <img
                                    src={spotifyColorIcon}
                                    alt="Spotify Icon"
                                    className='App-logo-spotify'
                                >
                                </img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageUnderConstruction;
