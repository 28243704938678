import React from 'react';
import Plot from 'react-plotly.js';
import { dataX, dataY, dataY1 } from '../plotlySection/data2'
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';


const PlotlySection2 = () => {
    return (
        <div className='container-fluid vh-100 w-100  justify-content-center align-items-center'>
            <Plot
                config={{ modeBarButtonsToRemove: ['toImage', 'zoomIn', 'zoomOut2d', 'lasso2d', 'select2d', 'autoScale2d', 'pan2d', 'zoom2d', ''] }}
                data={[
                    {
                        x: dataX,
                        y: dataY,
                        type: 'bar',
                        marker: { color: 'blue' },
                    },
                    {
                        x: dataX,
                        y1: dataY1,
                        type: 'lines',
                        marker: { color: 'blue' },
                    },
                ]}
                layout={{
                    width: 1366,
                    height: 500,
                    title: 'Patrimônio x Tempo',
                    yaxis: {
                        title: 'Patrimônio',
                        side: 'right'
                    },
                    xaxis: {
                        tickangle: -45, 
                        title: 'Ano',
                        tickvals: ['1994-01','1996-01', '2000-01','2010-01','2020-01'] ,
                        ticktext: ['1994-01','1996-01', '2000-01','2010-01','2020-01'] ,
                        type: 'date'
                    }
                }}

            />
        </div>
    );
}

export default PlotlySection2